import { Grid, Skeleton } from "@mui/material";
import { extractDateAndTime } from "../../helpers/FormatDateTime/FormatDateTime";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { UserServiceProvider } from "../../services";
import HistoryIcon from "@mui/icons-material/History";

function CreatedByInfo({ user }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const createdTimestamp = user?.createTimestamp;

  useEffect(() => {
    if (!user) {
      setLoading(true);
      return;
    }

    if (!("createTimestamp" in user)) {
      setError(true);
      setLoading(false);
      return;
    }

    setLoading(false);
    setError(null);
  }, [user]);

  if (loading) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) {
    return <div>Error:{error}</div>;
  }

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="created-by-info">
      <div>
        <CalendarTodayIcon />
        <b>Created</b> <br />
        <span className="created-by-name">
          {`${extractDateAndTime(createdTimestamp).formattedDate}`} at&nbsp;
          {`${extractDateAndTime(createdTimestamp).formattedTime}`}
        </span>
      </div>
    </Grid>
  );
}

function ModifiedByInfo({ id }) {
  const userService = UserServiceProvider();
  const [modifiedBy, setModifiedBy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [userCache, setUserCache] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      if (userCache[id]) {
        setUser(userCache[id]);
        setModifiedBy(userCache[id]);
        setLoading(false);

        return;
      }
      try {
        setLoading(true);
        const response = await userService.getUserByID("users", id);
        setModifiedBy(response?.data);
        setUser(response?.data);
        setUserCache((prevCache) => ({
          ...prevCache,
          [id]: response?.data,
        }));
      } catch (err) {
        setError(err.message || "An error occurred while fetching the user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id, userCache]);

  if (loading) {
    return (
      <Grid item xs={3} sm={4} md={5} lg={5}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            width: "100%",
            height: "60px",
            minWidth: "250px",
            maxWidth: "550px",
          }}
          data-testid="skeleton"
        />
      </Grid>
    );
  }

  if (error) return <div>Error: {error}</div>;
  if (!modifiedBy || !user?.modifierOid) return null;

  const modifiedTimestamp = user?.modifyTimestamp;

  return (
    <Grid item xs={3} sm={4} md={5} lg={5} className="modified-by-info">
      <div>
        <HistoryIcon />
        <b>Last updated</b> <br />
        <span className="modified-by-name">
          {`${extractDateAndTime(modifiedTimestamp).formattedDate}`} at&nbsp;
          {`${extractDateAndTime(modifiedTimestamp).formattedTime}`}
          {/* {modifiedBy.givenName && modifiedBy.familyName && (
            <>
              &nbsp;by {`${modifiedBy.givenName}`} {`${modifiedBy.familyName}`}
            </>
          )} */}
        </span>
      </div>
    </Grid>
  );
}

export { CreatedByInfo, ModifiedByInfo };

CreatedByInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

ModifiedByInfo.propTypes = {
  id: PropTypes.string.isRequired,
};
